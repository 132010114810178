@font-face {
  font-family: "Noto Sans New Tai Lue";
  src: url("./font/NotoSansNewTaiLue-Regular.ttf");
}
body {
  margin: 0;
 /* font-family: "Poppins", sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", sans-serif;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
:root {
  --primary-color: #b08561;
}
@media only screen and (max-width: 767px) {
  .hidden {
    display: none;
  }
}
